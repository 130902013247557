form {
    --color: hsla(var(--color-text), 60%);
	display: grid;
	gap: 1.5rem;
    letter-spacing: .05em;
	label {
		text-transform: uppercase;
		font-weight: 500;
		font-size: .9rem;
        letter-spacing: .05em;
        color: var(--color);
	}
    a {
        color: hsl(var(--color-primary));
        &:hover {
            text-decoration: none;
        }
    }
	input {
		flex-basis: 100%;
		border: none;
		border-bottom: 1px solid var(--color);
		border-radius: 0;
		background: transparent;
		color: var(--color);
		font-weight: 500;
		font-size: .9rem;
		padding: 0 0 .5rem 0;
        letter-spacing: .05em;
		&::placeholder {
			color: var(--color);
			text-transform: uppercase;
			font-weight: 500;
			font-size: .9rem;
			transition: opacity .6s var(--ease-out-quad);
		}
		&:focus-visible {
			outline: none;
		}
		&:focus-within {
			&::placeholder {
				opacity: .2;
			}
		}
	}
	textarea {
		margin-top: -1rem;
		flex-basis: 100%;
		border: 1px solid var(--color);
		border-radius: 0;
		background: transparent;
		color: var(--color);
		font-weight: 500;
		font-size: .8rem;
		line-height: 1.3;
		padding: .5rem;
		resize: none;
		&::placeholder {
			color: var(--color);
			text-transform: uppercase;
			font-weight: 500;
			font-size: .9rem;
			transition: opacity .6s var(--ease-out-quad);
		}
		&:focus-visible {
			outline: none;
		}
		&:focus-within {
			&::placeholder {
				opacity: .2;
			}
		}
	}
	.select-wrapper {
		position: relative;
		&:after {
			content:'';
			position: absolute;
			top: .1rem;
			right: 0;
			width: .8rem;
			height: .8rem;
			background-image: url('../img/caret-down.svg');
			background-size: contain;
			background-position: 50% 50%;
			background-repeat: no-repeat;
            opacity: .6;
 		}
	}
	select {
		display: block;
		width: 100%;
		-webkit-appearance: none;
    	-moz-appearance : none;
		cursor: pointer;
		background: transparent;
		border: none;
		border-bottom: 1px solid var(--color);
		color: var(--color);
		text-transform: uppercase;
		font-size: .9rem;
		font-weight: 500;
		padding: 0 0 .5rem 0;
		border-radius: 0;
        letter-spacing: .05em;
		&:focus-visible {
			outline: none;
			color: rgba(23, 48, 42, .4);
		}
	}
    input[type="checkbox"]{
        position: relative;
        top: .05rem;
        margin-right: .5rem;
    }
	button {
		justify-self: start;
		background: transparent;
		cursor: pointer;
	}
	.errors {
		list-style-type: none;
		margin: -1rem 0 0 0;
		padding: 0;
		li {
			font-weight: 500;
			color: red;
			font-size: .8rem;
		}
	}
	.small {
		font-size: .6rem;
		opacity: .8;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}