@font-face {
    font-family: 'inter';
    src: url('../fonts/inter-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'inter';
    src: url('../fonts/inter-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'inter';
    src: url('../fonts/inter-thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'inter';
    src: url('../fonts/inter-black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'inter';
    src: url('../fonts/inter-semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'inter';
    src: url('../fonts/inter-bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'inter';
    src: url('../fonts/inter-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

// @font-face {
//     font-family: 'inter-tight';
//     src: url('../fonts/intertight-light.woff2') format('woff2');
//     font-weight: 300;
//     font-style: normal;
//     font-display: block;
// }

// @font-face {
//     font-family: 'inter-tight';
//     src: url('../fonts/intertight-lightitalic.woff2') format('woff2');
//     font-weight: 300;
//     font-style: italic;
//     font-display: block;
// }

@font-face {
  font-family: "inter-tight";
  src: url("../fonts/intertight-variable.woff2") format("woff2-variations");
  font-weight: 200 800;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "plusjakartasans";
  src: url("../fonts/plusjakartasans.woff2") format("woff2-variations");
  font-weight: 200 800;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "plusjakartasans";
  src: url("../fonts/plusjakartasans-italic.woff2") format("woff2-variations");
  font-weight: 200 800;
  font-style: italic;
}