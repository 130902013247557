.bigtabs {

}

.bigtabs__heading {
    .list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .title {
        position: relative;
        flex: 1 1 0;
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        max-width: 15rem;
        padding: 1rem;
        font-weight: 400;
        font-size: .9rem;
        line-height: 1.3;
        opacity: .3;
        cursor: pointer;
        user-select: none;
        transition: opacity .2s var(--ease-out-quad);
        @media only screen and (min-width: $sm) {
            font-size: 1.1rem;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: hsl(var(--color-text));
        }
        &:hover {
            opacity: .4;
        }
        &.is-active {
            opacity: 1;
            font-weight: 500;
            cursor: default;
            &:after {
                height: 3px;
                bottom: -1px;
            }
        }
    }
}

.bigtabs__body {
    .list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: grid;
        margin-top: 2rem;
        @media only screen and (min-width: $md) {
            margin-top: 3rem;
        }
    }
    .content {
        grid-row: 1;
        grid-column: 1;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity .1s var(--ease-out-quad);
        &.is-active {
            transition: opacity .3s var(--ease-in-quad);
            visibility: visible;
            opacity: 1;
        }
        p {
            @extend h2;
            font-family: var(--font1);
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1.3;
            strong {
                font-weight: 400;
            }
        }
    }
}