.numbercardgrid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1.5rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
    @media only screen and (min-width: $lg) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.numbercardgrid__item {
    position: relative;
    padding: 4rem 2rem 4rem 2rem;
    background-color: rgba(233, 232, 229, 0.60);
    text-align: center;
    border-bottom-right-radius: 1rem;
    .number {
        display: block;
        margin-bottom: 1rem;
        color: hsla(var(--color-black), 40%);
        font-family: var(--font3);
        font-size: 4rem;
        font-weight: 200;
        letter-spacing: -3px;
        @media only screen and (min-width: $lg) {
            margin-bottom: 2.5rem;
        }
    }
    .text {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.4;
        font-family: var(--font1);
    }
    .footnote {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        text-align: left;
        font-size: 0.6rem;
        line-height: 1.2;
        font-style: italic;
        opacity: 0.4;
    }
}