.vacanciesCta {
    background-color: hsla(var(--color-grey), .5);
}

.vacanciesCta__top {
    position: relative;
}

.vacanciesCta__image {
    aspect-ratio: 16 / 9;
    @media only screen and (min-width: $lg) {
      aspect-ratio: 3 / 1;  
    }
}

.vacanciesCta__container {
    position: relative;
}

.vacanciesCta__intro {
    position: absolute;
    bottom: 0;
    left: var(--site-padding);
    width: calc(100% - calc(var(--site-padding) * 2));
    background: hsla(var(--color-grey), .6);
    padding: 2rem;
    backdrop-filter: blur(20px);
    @extend h2;
    color: white;
    @media only screen and (min-width: $md) {
        width: calc(50% - var(--site-padding));
        padding: 3rem;
    }
}

.vacanciesCta__bottom {

}

.vacanciesCta__bottom-container {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $md) {
        flex-direction: row;
    }
}

.vacanciesCta__col {
    flex: 1 1 50%;
    padding: 2rem;
    &:first-of-type {
        background-color: hsl(var(--color-grey));
    }
    @media only screen and (min-width: $md) {
        padding: 3rem;
    }
}

.vacanciesCta__list {
    margin: 0;
}

.vacanciesCta__item {

}

.vacanciesCta__link {
    text-transform: uppercase;
    color: inherit;
    text-decoration: none;
}