.search {

}

.search__form {
    position: relative;
    margin-bottom: 2rem;
    border: 1px solid hsl(var(--color-black));
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 30rem;
    input {
        border: none;
        padding: 0;
        &::placeholder {
			text-transform: unset;
		}
    }
    button {
        border: none;
        .ph {
            font-size: 1.2rem;
            line-height: 1.6;
        }
    }
}

.search__item {
    color: inherit;
}

.search__type {

}