.propertyCta {}

.propertyCta__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
    @media only screen and (min-width: $md) {
        flex-direction: row;
        align-items: center;
        gap: 2rem;
    }

    @media only screen and (min-width: $lg) {
        gap: 4rem;
    }
}

.propertyCta__col {
    flex: 1 1 0;
}

.propertyCta__content {
    & *:first-child {
        margin-top: 0;
    }

    h2 {
        margin-bottom: 2rem;
    }

    @media only screen and (min-width: $xl) {
        padding-left: 5rem;
    }
}

.propertyCta__card {
    display: flex;
    background-color: hsl(var(--color-grey));
    padding: 1rem;
    gap: 1.5rem;
    align-items: stretch;
    border-bottom-right-radius: 1rem;
    @media only screen and (min-width: $md) {
        padding: 2rem;
        gap: 2rem;
    }

    @media only screen and (min-width: $lg) {
        gap: 4rem;
    }

    .col {}

    .image {
        display: block;
        flex: 0 0 50%;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1rem 0 0;
        @media only screen and (min-width: $lg) {
            padding: 2rem 0;
        }
    }

    .list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 1rem;
            @media only screen and (min-width: $md) {
                margin-bottom: 2rem;
            }
        }
    }

    .title {
        display: block;
        margin-bottom: .75;
        @media only screen and (min-width: $lg) {
            margin-bottom: 1rem;
        }
    }

    .value {
        display: block;
        font-weight: 500;
    }
}