#map {
    position: relative;
    width: 100%;
    // aspect-ratio: 16 / 9;
    height: 20rem;
    @media only screen and (min-width: $md) {
        height: 100%;
    }
}

.map--home {
    aspect-ratio: unset;
    height: 100%!important;
}

.map--entry {
    aspect-ratio: unset;
    height: 20rem!important;
    margin-bottom: 2rem;
}

.mapboxgl-ctrl-attrib-inner {
    font-size: .5rem;
    > a {
        font-size: inherit;
    }
}


.mapboxgl-marker {
    background-image: url('../img/marker.png');
    background-size: contain;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.mapboxgl-popup {
  
}


.mapboxgl-popup-content {
    box-sizing: content-box;
    padding: .75rem!important;
    max-width: calc(150px + 2rem);
    display: grid;
    box-shadow: 0 10px 20px hsl(0deg 0% 0% / 20%)!important;
    .title {
        display: block;
        padding-right: 1rem;
        font-weight: 600;
        font-size: 1.1rem;
    }
    .address {
        font-size: .7rem;
        margin-top: .35rem;
        line-height: 1.4;
    }
    .thumbnail {
        display: block;
        margin-top: .6rem;
        width: 160px;
        height: auto;
    }
    .button {
        display: block;
        margin-top: 1rem;
        justify-self: flex-start;
    }
}

.mapboxgl-popup-close-button {
    font-size: 1rem;
}