.documentslist {
    display: grid;
    gap: 1.5rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    @container (min-width: 500px){
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @container (min-width: 900px){
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.documentslist-item {
    position: relative;
    background-color: white;
    border-bottom-right-radius: var(--border-radius);
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: hsl(var(--color-primary));
        opacity: 0;
        transition: opacity .1s var(--ease-out-quad);
    }
}

.documentslist-link {
    display: block;
    height: 100%;
    text-decoration: none;
    color: inherit;
}

.documentslist-inner {
    position: relative;
    height: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-between;
}

.documentslist-body {

}

.documentslist-bottom {
    padding-right: 3rem;
}

.documentslist-title {
    letter-spacing: unset;
    font-family: $font1;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.5;
    margin: 0;
    color: hsla(var(--color-black), 85%);
}

.documentslist-filename {
    display: flex;
    align-items: flex-start;
    gap: .3rem;
    font-size: .75rem;
    font-weight: 600;
    color: hsl(var(--color-primary));
    i {
        font-size: 1.1rem;
    }
}

.documentslist-categories {
    list-style-type: none;
    margin: 0;
    margin-bottom: .2rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.documentslist-categories-item {
    font-size: .75rem;
    // color: hsl(var(--color-primary));
    font-weight: 500;
    letter-spacing: 0.5px;
    opacity: .5;
    &:not(:last-of-type){
        &:after {
            content: '/';
            margin: 0 .25rem;
        }
    }
}

.documentslist-datelist {
    list-style-type: none;
    margin: .2rem 0 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.documentslist-datelist-item {
    font-size: .75rem;
    font-weight: 600;
    opacity: .6;
    &:not(:last-of-type){
        &:after {
            content: '/';
            margin: 0 .25rem;
        }
    }
}

.documentslist-date {
    font-size: .75rem;
    font-weight: 600;
    opacity: .5;
}

.documentslist-icon {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    font-size: 1.5rem;
    opacity: .6;
    transition: opacity .2s var(--ease-out-quad);
}


/* ------------------------------------------------------ */
/*                         states                         */
/* ------------------------------------------------------ */

.documentslist-item:hover {
    &:before {
        opacity: 1;
    }
    .documentslist-title {
        color: hsla(var(--color-black), 100%);
    }
    .documentslist-icon {
        opacity: 1;
    }
}