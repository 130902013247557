.object-fit {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
	}
}

.center {
    display: flex;
    justify-content: center;
}

.overflow-hidden {
    overflow: hidden;
}