.discover {
    display: grid;
    @media only screen and (min-width: $lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.discover__col {
    padding-top: 2rem;
    @media only screen and (min-width: $lg) {
        padding-top: 0;
    }
}

.discover__top {
    position: relative;
    z-index: 2;
    padding: 0 0 0 var(--site-padding);
    @media only screen and (min-width: $lg) {
        padding: 0 0 0 6rem;
    }
}

.discover__title {
    position: relative;
    padding: 1rem 1rem 3rem 1rem;
    margin-bottom: -1.5rem;
    color: hsl(var(--color-primary));
    font-family: var(--font1);
    font-weight: 300;
    line-height: 1.1;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: hsl(var(--color-primary));
    }
}

.discover__spotlight {
    position: relative;
    aspect-ratio: 3 / 4;
    background-color: #3f3f3f;
    @media only screen and (min-width: $lg) {
        aspect-ratio: 4 / 3;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .8;
    }

    .body {
        position: absolute;
        bottom: var(--site-padding);
        left: var(--site-padding);
        width: calc(100% - var(--site-padding) * 2);
        background-color: hsla(var(--color-grey), 97%);
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom-right-radius: 1rem;
        @media only screen and (min-width: $lg) {
            bottom: 2rem;
            left: 2rem;
            width: calc(100% - 4rem);
            flex-direction: row;
            align-items: center;
            padding-top: 0;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-basis: 100%;
        }

        li {
            position: relative;
            padding: 0 1.5rem;
            @media only screen and (min-width: $md) {
                padding: 0 2rem;
            }
        }

        .heading {
            position: relative;
            display: block;
            text-transform: uppercase;
            font-size: .7rem;
            font-weight: 600;
            letter-spacing: 1.17px;
            color: hsla(var(--color-black), 40%);
            @media only screen and (min-width: $lg) {
                position: absolute;
                top: -1.5rem;
            }
        }

        .property {
            display: block;
            font-weight: 500;
            font-size: 1.1rem;
            @media only screen and (min-width: $md) {
                font-size: 1.2rem;
            }
        }

        .buttonwrapper {
            width: 100%;
            padding: 1rem;
            text-align: right;
            border-top: 2px solid white;
            margin-top: 1rem;
            @media only screen and (min-width: $lg) {
                width: auto;
                padding: 4rem 2rem 4rem 2rem;
                margin-top: 0;
                border-left: 2px solid white;
                border-top: none;
            }
        }

        .button {}
    }
}

.discover__map {
    position: relative;
    aspect-ratio: 1;
    @media only screen and (min-width: $lg) {
        aspect-ratio: 4 / 3;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}