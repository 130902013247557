.teamgrid {
    &:first-of-type {
        padding-top: 0;
    }
    &:nth-of-type(odd){
        background-color: hsl(var(--color-white));
    }
}

.teamgrid-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 1.5rem;
    @media only screen and (min-width: $md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.teamgrid-card {
    border: 1.5px solid hsl(var(--color-primary));
    border-bottom-right-radius: var(--border-radius);
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: start;
    justify-content: start;
    gap: 1rem;
    @media only screen and (min-width: $lg) {
        gap: 2rem;
    }
    @media only screen and (min-width: $xl) {
        gap: 4rem;
    }
}

.teamgrid-card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    background-color: hsl(var(--color-grey));
    aspect-ratio: 3 / 4;
    @media only screen and (min-width: $lg) {
        width: 8rem;
    }
    i {
        font-size: 4rem;
        opacity: .1;
        @media only screen and (min-width: $xl) {
            font-size: 5rem;
        }
    }
}

.teamgrid-card-body {
    padding: 1rem 0;
    .name {
        margin: 0;
        color: hsl(var(--color-primary));
        font-size: clamp(1.2rem, 3vw, 2rem);
    }
    .function {
        display: block;
        font-size: 1.1rem;
        margin-top: 1rem;
        font-weight: 600;
    }
    .mail {
        display: inline-block;
        margin-top: .25rem;
        font-size: .85rem;
        color: hsl(var(--color-text));
        word-break: break-all;
    }
    .text {
        margin-top: .5rem;
        font-size: .85rem;
        font-weight: 300;
    }
}