.mobilenav {
    position: absolute;
    z-index: 1;
    top: var(--header-height);
    left: 0;
    width: 100%;
    background-color: white;
    padding: 1.5rem;
    display: grid;
    gap: 1.5rem;
    justify-items: start;
    align-content: start;
    transform: translateY(-200%);
    transition: transform .5s var(--ease-out-quad);
    height: calc(100dvh - var(--header-height));
    @media only screen and (min-width: $lg) {
        display: none;
    }
    &.is-open {
        transform: translateY(0);
    }
}