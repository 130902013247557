.pagination {
    display: flex;
    gap: .5rem;
    padding-top: 1.5rem;
    @container (min-width: 500px) {
        justify-content: center;
    }
    @container (min-width: 900px) {
        padding-top: 3rem;
    }
}

.pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.8rem;
    height: 1.8rem;
    border: 2px solid hsl(var(--color-primary));
    font-size: .8rem;
    font-weight: 700;
}

.pagination-link {
    text-decoration: none;
    color: hsl(var(--color-primary));
    &:hover {
        background-color: hsla(var(--color-primary), 10%);
    }
}

.pagination-current {
    background-color: hsl(var(--color-primary));
    color: white;
}

.pagination-prev,
.pagination-next {
    text-decoration: none;
    border: none;
    color: hsl(var(--color-primary));
    font-weight: 600;
    font-size: .8rem;
    &:hover {
        opacity: .85;
    }
}

.pagination-prev {

}

.pagination-next {

}