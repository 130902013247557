.tablewrapper {
    overflow-x: scroll;
    @media only screen and (min-width: $lg) {
        overflow-x: auto;
    }
}

table {
    border-collapse: collapse;
    margin: 0;
    width: 0;
    min-width: 50rem;
    width: 100%;
    &.no-min-width {
        min-width: unset;
    }
}

table tr:first-of-type th {
    background-color: hsla(var(--color-primary), 100%);
    color: white;
}

table tr:not(:first-of-type) th,
table tr:not(:first-of-type) td:first-of-type {
    background-color: hsla(var(--color-primary), 7%);
}

table th {
    text-align: left;
}

table th,
table td {
    padding: .75rem 1rem;
    p {
        margin-top: 0!important;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

table tbody tr {
    border-bottom: 1px solid #dddddd;
}

table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

table tbody tr:last-of-type {
    border-bottom: 2px solid hsl(var(--color-primary));
}

table tr:hover {
    color: hsl(var(--color-primary));
    background-color: hsla(var(--color-primary), 5%)!important;
}