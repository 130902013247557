.glossary {

}

.glossary-grid {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 1.5rem;
    @media only screen and (min-width: $md) {
        grid-template-columns: repeat(2, minmax(0,1fr));
    }
    @media only screen and (min-width: $lg) {
        grid-template-columns: repeat(3, minmax(0,1fr));
    }
    // @media only screen and (min-width: $xxl) {
    //     grid-template-columns: repeat(4, minmax(0,1fr));
    // }
}

.glossary-item {
    background-color: white;
    padding: 1.5rem;
    border-bottom-right-radius: var(--border-radius);
    .title {
        margin: 0;
        font-family: $font2;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.3;
    }
    .text {
        margin-top: 1.5rem;
        font-size: .8rem;
    }
}