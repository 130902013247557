.values {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
    @media only screen and (min-width: $lg) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        padding: 0 2rem;
    }
}

.values__item {
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    .line {
        display: block;
        height: 6rem;
        width: 2px;
        background: hsl(var(--color-primary));
        @media only screen and (min-width: $md) {
            height: 10rem;
        }
    }
    .text {
        display: block;
        padding: 0 0 1rem 1rem;
        color: hsl(var(--color-primary));
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.1;
        word-break: break-word;
    }
    
}