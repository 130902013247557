$spacings: (0, 1, 2, 3, 4, 5, 6, 7, 8, auto);

$sides: (
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
  "": (
    "top",
    "left",
    "bottom",
    "right"
  ),
  "x": (
    "left",
    "right"
  ),
  "y": (
    "top",
    "bottom"
  )
);

$breakpoints: (
  "": "",
  "sm": 460px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1200px,
  "xxl": 1536px,
  "xxxl": 1920px
);

@each $breakName, $breakValue in $breakpoints {
  @each $space in $spacings {
    @each $prefix, $positions in $sides {
      @if $breakName != "" {
        $prefix: #{$prefix}-#{$breakName};
      } @else {
        $prefix: #{$prefix};
      }

      @if $breakName != "" {
        @media (min-width: $breakValue) {
          .p#{$prefix}-#{$space} {
            @each $pos in $positions {
              padding-#{$pos}: if($space == auto, $space, #{$space * 1}rem);
            }
          }
          .m#{$prefix}-#{$space} {
            @each $pos in $positions {
              margin-#{$pos}: if($space == auto, $space, #{$space * 1}rem);
            }
          }
        }
      } @else {
        .p#{$prefix}-#{$space} {
          @each $pos in $positions {
            padding-#{$pos}: if($space == auto, $space, #{$space * 1}rem);
          }
        }
        .m#{$prefix}-#{$space} {
          @each $pos in $positions {
            margin-#{$pos}: if($space == auto, $space, #{$space * 1}rem);
          }
        }
      }
    }
  }
}