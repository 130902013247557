.subnav {

}

.subnav__nav {

}

.subnav__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $md) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.subnav__item {
    flex: 1 1 0;
}

.subnav__link {
    position: relative;
    top: 1px;
    display: block;
    text-align: center;
    padding: .5rem .5rem;
    font-size: .65rem;
    text-transform: uppercase;
    color: hsl(var(--color-text));
    font-family: var(--font2);
    font-weight: 500;
    text-decoration: none;
    border-top: 1px solid hsl(var(--color-text));
    opacity: .3;
    transition: opacity .2s var(--ease-out-quad);
    @media only screen and (min-width: $md) {
        padding: 1rem .5rem;
    }
    @media only screen and (min-width: $lg) {
        font-size: .9rem;
    }
    &.is-active, 
    &:hover {
        top: 0px;
        opacity: 1;
        border-width: 3px;
    }
}