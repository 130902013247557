.teamtiles {
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: 10rem;
    @media only screen and (min-width: $md) {
        --slide-size: 50%;
        --slide-spacing: 2rem;
    }
}

.teamtiles__buttons {
    position: absolute;
    right: var(--site-padding);
    bottom: 0;
    display: flex;
    gap: 2rem;
}

.teamtiles__button {
    display: block;
    padding: 0;
    border: none;
    background: transparent;
    height: 2rem;
    cursor: pointer;
    svg {
        height: 100%;
        width: auto;
    }
    &--prev {
        transform: rotate(180deg);
    }
    &[disabled]{
        opacity: .2;
        cursor: default;
    }
}

.teamtiles__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: calc(var(--slide-spacing) * -1);
}

.teamtiles__slide {
    display: flex;
    flex-direction: column;
    gap: var(--slide-spacing);
    flex: 0 0 var(--slide-size);
    padding-left: var(--slide-spacing);
    min-width: 0;
}

.teamtiles__item {
    position: relative;
    border: 1px solid hsl(var(--color-primary));
    border-bottom-right-radius: 1rem;
    overflow: hidden;
    height: var(--slide-height);
    display: flex;
    align-items: center;
    &:hover {
        .teamtiles__mail {
            opacity: 1;
        }
    }
}

.teamtiles__link {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
    @media only screen and (min-width: $lg) {
        padding: 1.5rem 4rem;
        gap: 4rem;
    }
}

.teamtiles__name {
    flex: 1 1 50%;
    color: hsl(var(--color-primary));
    font-size: 1.2rem;
}

.teamtiles__function {
    flex: 1 1 50%;
    font-family: var(--font3);
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 600;
}

.teamtiles__mail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsl(var(--color-primary));
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.3rem;
    font-weight: 400;
    opacity: 0;
    transition: opacity .15s var(--ease-out-quad);
}