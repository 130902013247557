@import '1_config/mixins.scss';
@import '1_config/fonts.scss';
@import '1_config/variables.scss';
@import 'easings-css';

@import 'modern-normalize/modern-normalize.css';
// @import "@fancyapps/ui/dist/fancybox/fancybox.css";

@import '2_base/base.scss';
@import '2_base/sections.scss';
@import '2_base/helpers.scss';
@import '2_base/typography.scss';
@import '2_base/richtext.scss';

@import '3_atoms/container.scss';
@import '3_atoms/button.scss';
@import '3_atoms/table.scss';
@import '3_atoms/download.scss';
@import '3_atoms/form.scss';
@import '3_atoms/menu-toggle.scss';

@import '4_components/header.scss';
@import '4_components/site-nav.scss';
@import '4_components/language-switcher.scss';
@import '4_components/mobilenav.scss';
@import '4_components/bighero.scss';
@import '4_components/mediumhero.scss';
@import '4_components/content.scss';
@import '4_components/cta-slider.scss';
@import '4_components/discover.scss';
@import '4_components/cardgrid.scss';
@import '4_components/values.scss';
@import '4_components/bigquote.scss';
@import '4_components/numbercardgrid.scss';
@import '4_components/buttoncta.scss';
@import '4_components/cta-newsletter.scss';
@import '4_components/fullwidth-image.scss';
@import '4_components/footer.scss';
@import '4_components/subnav.scss';
@import '4_components/property-cta.scss';
@import '4_components/two-images.scss';
@import '4_components/lead.scss';
@import '4_components/bigtabs.scss';
@import '4_components/history.scss';
@import '4_components/teamtiles.scss';
@import '4_components/vacancies-cta.scss';
@import '4_components/sidebar.scss';
@import '4_components/newslist.scss';
@import '4_components/documentslist.scss';
@import '4_components/pagination.scss';
@import '4_components/article.scss';
@import '4_components/propertiesmap.scss';
@import '4_components/filtercard.scss';
@import '4_components/glossary.scss';
@import '4_components/accordeon.scss';
@import '4_components/teamgrid.scss';
@import '4_components/contactgrid.scss';
@import '4_components/contactform.scss';
@import '4_components/property.scss';
@import '4_components/popup.scss';
@import '4_components/errorpage.scss';
@import '4_components/search.scss';
@import '4_components/chart.scss';


@import '5_organisms/main-aside.scss';

@import '7_utilities/spacing.scss';

@import "@fancyapps/ui/dist/fancybox/fancybox.css";
@import "@phosphor-icons/web/src/light/style.css";
@import "@phosphor-icons/web/src/regular/style.css";