.cardgrid {
    display: grid;
    gap: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    @media only screen and (min-width: $md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (min-width: $lg) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.5rem;
    }
}

.cardgrid__item {

}

.cardgrid__item--properties {
    position: relative;
    background-color: hsla(var(--color-grey), 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    left: calc(var(--site-padding) * -1);
    width: calc(100% + var(--site-padding) * 2);
    margin-top: -1.5rem;
    @media only screen and (min-width: $md) {
        left: unset;
        width: 100%;
        margin-top: 0;
        grid-column: 2;
    }
    @media only screen and (min-width: $lg) {
        grid-column: 2 / span 2;
    }
    @media only screen and (min-width: $xl) {
        min-height: 30rem;
    }
    p {
        padding: 2rem;
    }
}

#propertiesmap {
    grid-column: 2 / span 2;
}

.cardgrid__item-link {
    text-decoration: none;
    display: block;
    color: inherit;
}

.cardgrid__item-image {
    position: relative;
    aspect-ratio: 4 / 3;
    overflow: hidden;
    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .2s var(--ease-out-quad), opacity .3s var(--ease-out-quad);
    }
}

.cardgrid__item-body {
    background-color: hsl(var(--color-grey));
    padding: 1.5rem 1.5rem;
    font-weight: 500;
    transition: background-color .2s var(--ease-out-quad);
    border-bottom-right-radius: 1rem;
    @media only screen and (min-width: $lg) {
        padding: 2rem 2rem;
    }

    .list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        // li:not(:last-of-type) {
        //     &:after {
        //         content: '';
        //         position: relative;
        //         display: inline-block;
        //         margin: 0 .5rem;
        //         vertical-align: middle;
        //         height: 1rem;
        //         width: 2px;
        //         background-color: hsl(var(--color-black));
        //     }
        // }
    }

    .city {
        text-transform: uppercase;
        font-weight: 700;
    }

    .street {}
}


/* ------------------------------------------------------ */
/*                         states                         */
/* ------------------------------------------------------ */

.cardgrid__item:hover {
    .cardgrid__item-image .image {
        transform: scale(1.025);
        opacity: .95;
    }
    .cardgrid__item-body {
        background-color: hsl(var(--color-grey--dark));
    }
}