.lead {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @media only screen and (min-width: $lg) {
        flex-direction: row;
        align-items: center;
        gap: 6rem;
    }
}

.lead__title {
    position: relative;
    flex: 1 0 auto;
    padding: 1rem 0 1rem 1rem;
    min-width: 0; // !!!
    //max-width: 28rem;
    @media only screen and (min-width: $lg) {
        padding: 1rem 0 1rem 2rem;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: hsl(var(--color-primary));
    }
    .title {
        @extend h2;
        margin: 0;
        color: hsl(var(--color-primary));
    }
}

.lead__body {
    flex: 1 1 auto;
}