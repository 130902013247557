.mainAside {
    display: grid;
    gap: 1.5rem;
    @media only screen and (min-width: $lg) {
        grid-template-columns: repeat(12, minmax(0,1fr));
    }
    @media only screen and (min-width: $xxl) {
        gap: 3rem;
    }
}

.mainAside-main {
    container-type: inline-size;
    @media only screen and (min-width: $lg) {
        grid-column: 1 / span 8;
    }
    &--white {
        @media only screen and (min-width: $lg) {
            background-color: white;
            padding: 2rem;
        }
    }
}

.mainAside-aside {
    container-type: inline-size;
    @media only screen and (min-width: $lg) {
        grid-column: 9 / span 4;
    }
}