.section {

}

.section__title {
	text-align: center;
	font-size: .9rem;
	letter-spacing: 1.44px;
    font-family: var(--font3);
    font-weight: 700;
    text-transform: uppercase;
}

/* ------------------------------------------------------ */
/*                        variants                        */
/* ------------------------------------------------------ */

.section--grey {
	background-color: hsl(var(--color-grey--light));
}

.section--darkgrey {
	background-color: hsl(var(--color-grey));
}

.section--white {
	background-color: hsl(var(--color-white));
}