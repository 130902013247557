.site-nav {

}

.site-nav__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    @media only screen and (min-width: $lg) {
        flex-direction: row;
        flex-wrap: wrap;
    }

}

.site-nav__item {
    &:not(:last-of-type){
        margin-right: .5rem;
        @media only screen and (min-width: 1350px) {
            margin-right: 1.5rem;
        }
    }
    &[data-home]{
        @media only screen and (min-width: $lg) {
            display: none;
        }
    }
}

.site-nav__link {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    font-family: var(--font2);
    color: hsl(var(--color-text));
    &:hover {
        color: hsl(var(--color-primary));
    }
    &.is-active {
        color: hsl(var(--color-primary));
        font-weight: 600;
    }
    .ph {
        line-height: 1.6;
    }
}