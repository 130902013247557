.button {
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 500;
	font-size: 1rem;
	letter-spacing: .04em;
	line-height: 1.2;
	font-family: var(--font2);
	color: hsl(var(--color-text));
	border: 1.5px solid hsl(var(--color-text));
	padding: .85rem 1.5rem;
	border-radius: 0;
	border-bottom-right-radius: 10px;
	transition: opacity .3s var(--ease-out-quad);
	&:hover {
		cursor: pointer;
	}
}

.button--primary {
	color: hsl(var(--color-primary));
	border: 2px solid hsl(var(--color-primary));
	padding: 1.5rem 3rem;
	&:hover {
		color: white;
		background-color: hsl(var(--color-primary));
	}
}

.button--secondary {
	background-color: hsl(var(--color-primary));
	color: white;
}

.button--sm {
	padding: .85rem 1.25rem;
	font-size: .85rem;
}

.button--white {
	color: white;
	border-color: white;
	&:hover {
		//background-color: rgba(256, 256, 256, .2);
	}
}

.button--link {
	position: relative;
	border: none;
	border-bottom: 1.5px solid hsl(var(--color-text));
	border-radius: 0;
	padding: 0 0 .5rem 0;
	margin-right: 2.5rem;
	font-size: .7rem;
	font-weight: 600;
	@media only screen and (min-width: $sm) {
		font-size: .85rem;
	}
	&:after {
		content: '';
		position: absolute;
		top: calc(50% - .4rem - .25rem);
		right: -2.5rem;
		display: inline-block;
		width: 2.5rem;
		height: .8rem;
		background-image: url('../img/icon-arrow-long.svg');
		background-size: contain;
		background-position: 100% 50%;
		background-repeat: no-repeat;
		transition: transform .2s var(--ease-out-quad);
	}
	&:hover {
		&:after {
			transform: translateX(.2rem);
		}
	}
}

.button--link--small {
	position: relative;
	border: none;
	padding: 0;
	margin-right: 1.5rem;
	font-size: .75rem;
	font-weight: 600;
	&:after {
		top: .1rem;
		right: -1.5rem;
		width: 2rem;
		height: .6rem;
	}
}