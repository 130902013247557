.filtercard {
    position: relative;
    background-color: hsl(var(--color-grey));
    padding: 1.5rem;
    display: grid;
    align-content: start;
    gap: 1.5rem;
    left: calc(var(--site-padding) * -1);
    width: calc(100% + var(--site-padding) * 2);
    @media only screen and (min-width: $md) {
        left: unset;
        width: auto;
        padding: 2rem;
        gap: 2rem;
        border-bottom-right-radius: var(--border-radius);
    }
}

.filtercard-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
    margin: 0;
    font-weight: 300;
    color: hsl(var(--color-primary));
    &:after {
        content: "";
        display: block;
        flex-basis: 100%;
        margin-top: 1rem;
        width: 100%;
        height: 1.5px;
        background: hsla(var(--color-primary), 15%);
    }
}

.filtercard-subtitle {
    margin: 0 0 1rem 0;
    font-size: clamp(1.3rem, 4.5vw, 1.8rem);
    font-weight: 500;
    font-family: $font1;
    letter-spacing: .01em;
}

.filtercard-dropdown {
    .list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        user-select: none;
    }
    .list-item {
        position: relative;
        &:not(:first-of-type){
            border-top: 1px solid hsla(var(--color-black), 15%);
        }
        &:has(.is-active){
            .title {
                //color: hsla(var(--color-primary));
            }
            .caret {
                //fill: hsla(var(--color-primary));
                transform: rotate(180deg);
            }
        }
        .title {
            position: relative;
            z-index: 2;
            display: block;
            padding: .5rem 0;
            font-size: 1.1rem;
            font-weight: 500;
            &:hover {
                cursor: pointer;
                color: hsla(var(--color-primary));
                & + .caret {
                    fill: hsla(var(--color-primary));
                }
            }
        }
        .caret {
            position: absolute;
            z-index: 1;
            top: .9rem;
            right: 0;
            width: 1.2rem;
            height: auto;
        }
    }
    .sublist {
        list-style-type: none;
        margin: 0;
        padding: 0rem 0 1rem;
        display: none;
        flex-wrap: wrap;
        gap: .3rem;
        @media only screen and (min-width: $md) {
            gap: .5rem;
        }
        &.is-active {
            display: flex;
        }
        &:has(.is-active){
            display: flex;
        }
    }
    .sublist-item {

    }
    .sublist-link {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 500;
        font-size: .6rem;
        letter-spacing: .04em;
        line-height: 1.2;
        font-family: var(--font2);
        color: hsl(var(--color-text));
        border: 1px solid hsl(var(--color-text));
        padding: .4rem .6rem;
        border-radius: 0;
        border-bottom-right-radius: 10px;
        transition: background-color .05s var(--ease-out-quad);
        @media only screen and (min-width: $md) {
            font-size: .7rem;
        }
        &:hover,
        &.is-active {
            background-color: hsl(var(--color-text));
            color: white;
        }
    }
}

.filtercard-filterlist {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: .3rem;
    @media only screen and (min-width: $md) {
        gap: .5rem;
    }
}

.filtercard-filteritem {

}

.filtercard-filterlink {
    position: relative;
	display: inline-block;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 500;
	font-size: .6rem;
	letter-spacing: .04em;
	line-height: 1.2;
	font-family: var(--font2);
	color: hsl(var(--color-text));
	border: 1px solid hsl(var(--color-text));
	padding: .4rem .6rem;
	border-radius: 0;
	border-bottom-right-radius: 10px;
	transition: background-color .05s var(--ease-out-quad);
    @media only screen and (min-width: $md) {
        font-size: .7rem;
    }
    &:hover,
    &.is-active {
        background-color: hsl(var(--color-text));
        color: white;
    }
}

.filtercard-reset {
    display: none;
    align-items: center;
    gap: .25rem;
    justify-self: flex-end;
    font-size: .75rem;
    text-decoration: none;
}