.popup {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(var(--color-grey), 80%);
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
}

.popup__inner {
    position: relative;
    display: block;
    max-width: 60rem;
    max-height: 75vh;
    overflow: auto;
    background-color: hsl(var(--color-white));
    padding: 1.5rem;
    font-size: .9rem;
    box-shadow: 0 0 15px hsla(var(--color-black), 15%);
    border-bottom-right-radius: 1rem;
    @media only screen and (min-width: $md) {
        padding: 2rem;
    }
    @media only screen and (min-width: $lg) {
        max-height: 90vh;
    }
    @media only screen and (min-width: $xxl) {
        padding: 3rem;
    }
}

.popup__buttons {
    display: flex;
    margin-top: 2rem;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.popup__button {
    background-color: white;
}

.popup__close {
    position: fixed;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    @media only screen and (min-width: $lg) {
        position: absolute;
    }
    &:hover {
        opacity: .7;
    }
}