.accordeon {
    background-color: white;
    padding: .5rem 1rem;
    border-bottom-right-radius: var(--border-radius);
    @media only screen and (min-width: $md) {
        padding: .5rem 1.5rem;
    }
    @media only screen and (min-width: $xl) {
        padding: .75rem 2rem;
    }
    &:not(:first-child){
        margin: 1rem 0;
        @media only screen and (min-width: $md) {
            margin: 1.5rem 0;
        }
    }
}

.accordeon-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.accordeon-item {
    position: relative;
    &:not(:last-of-type){
        border-bottom: 1.5px solid hsl(var(--color-text));
    }
    &.is-visible {
        .accordeon-title {
            color: hsl(var(--color-primary));
        }
        .accordeon-icon {
            color: hsl(var(--color-primary));
        }
        .accordeon-body {
            display: block;
        }
        .accordeon-icon {
            transform: rotate(180deg);
        }
    }
}

.accordeon-title {
    display: block;
    padding: 1.2rem 2.5rem 1.2rem 0;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    @media only screen and (min-width: $md) {
        font-size: 1.1rem;
    }
    &:hover {
        color: hsl(var(--color-primary));
    }
}

.accordeon-icon {
    position: absolute;
    top: 1.4rem;
    right: 0;
    font-size: 1.3rem;
    pointer-events: none;
    transition: transform .2s var(--ease-out-quad);
    @media only screen and (min-width: $md) {
        font-size: 1.6rem;
    }
}

.accordeon-body {
    display: none;
    max-width: 50rem;
    margin-bottom: 2.5rem;
    font-size: .8rem;
    border-left: 1px solid hsla(var(--color-text), 10%);
    padding-left: 1rem;
    @media only screen and (min-width: $md) {
        font-size: .9rem;
        padding-left: 1.25rem;
    }
    p {
        margin: 1rem 0;
    }
}