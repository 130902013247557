.contactform {
    @media only screen and (min-width: $lg) {
        padding: 0 0 0 2rem;
        max-width: 42rem;
    }
    @media only screen and (min-width: $xxl) {
        padding: 0 4rem;
    }
}

.contactform .message {
    display: block;
    padding: 1rem;
    background: white;
    margin-bottom: 2rem;
    font-weight: 600;
}