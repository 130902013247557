.header {
    position: relative;
    z-index: 1000;
    top: 0;
    width: 100%;
    font-weight: 500;
    background-color: hsl(var(--color-white));
    transition-property: transform;
    transition-duration: .4s;
    transition-timing-function: var(--ease-out-quad);
    @media only screen and (min-width: $lg) {
        background-color: hsla(var(--color-white), 95%);
    }
}

.header__container {
    position: relative;
    //background-color: inherit;
    z-index: 2;
    display: flex;
    height: var(--header-height);
    gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
}

.header__col {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.header__col:nth-of-type(1){

}

.header__col:nth-of-type(2){
    display: none;
    @media only screen and (min-width: $lg) {
        display: block;
    }
}

.header__col:nth-of-type(3){
    display: none;
    @media only screen and (min-width: $lg) {
        display: flex;
        align-items: center;
    }
}

.header__logo {
    width: 160px;
    transform: translateY(3px);
    @media only screen and (min-width: $lg) {
        width: 199px;
    }
    svg {
        width: 160px;
        height: auto;
        @media only screen and (min-width: $lg) {
            width: 199px;
        }
    }
}

/* ------------------------------------------------------ */
/*                        variants                        */
/* ------------------------------------------------------ */

.header--transparent:not([data-top="false"]) {
    --menu-toggle-color: white;
    position: absolute;
    background: transparent;
    color: white;
    .header__logo svg {
        .letter {
            fill: white;
        }
    }
    .header__container:after {
        background: white;
    }
    .site-nav__link,
    .language-switcher__link {
        color: white;
        &:hover {
            color: white;
            opacity: .7;
        }
    }
    .button--primary {
        color: white;
        border-color: white;
        &:hover {
            color: hsl(var(--color-text));
            background-color: white;
        }
    }
}



.header[data-pinned="true"]{
    transform: translateY(0);
    @media only screen and (min-width: $lg) {
        backdrop-filter: blur(10px);
    }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        // left: var(--site-padding);
        // width: calc(100% - var(--site-padding) * 2);
        left: 0;
        width: 100%;
        height: 1.5px;
        background: hsl(var(--color-grey));
    }
}

.header[data-pinned="false"]{
    transform: translateY(-100%);
}

.header[data-top="true"]{
    transform: translateY(0);
}

.header[data-top="false"]{
    position: fixed;
    top: 0;
    left: 0;
    --header-height: 80px;
    .header__logo {
        transform: translateY(3px);
        svg {
            width: 120px;
            height: auto;
        }
    }
}

.header[data-no-transition="true"]{
    transition-duration: 0s;
}

.header[data-top="false"]:not(.header--transparent) + .main {
    margin-top: var(--header-height);
}

.header[nav-open="true"]{
    transform: translateY(0)!important;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        // left: var(--site-padding);
        // width: calc(100% - var(--site-padding) * 2);
        left: 0;
        width: 100%;
        height: 1.5px;
        background: hsl(var(--color-grey));
    }
}