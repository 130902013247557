.article {

}

.article-title {
    margin: 0 0 1rem 0;
    font-family: $font1;
    font-size: var(--font-size-h4);
    line-height: 1.3;
    letter-spacing: unset;
    font-weight: 500;
}

.article-meta {
    display: flex;
    flex-wrap: wrap;
    gap: .25rem 1.25rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid hsla(var(--color-black), 5%);
}

.article-date {
    @extend .newslist-date;
}

.article-categories {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.article-categories-item {
    font-size: .75rem;
    color: hsl(var(--color-primary));
    font-weight: 600;
    &:not(:last-of-type){
        &:after {
            content: '/';
            margin: 0 .25rem;
        }
    }
}

.article-body {
    margin-top: 2rem;
}

.article-video {
    display: block;
    width: 100%;
}

.article .download {
    background-color: hsl(var(--color-grey--lighter));
}