.buttoncta {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    
    @media only screen and (min-width: $lg) {
        flex-direction: row;
        align-items: stretch;
        gap: 2rem;
    }
}

.buttoncta__button {
    position: relative;
    flex: 1 1 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 4rem 4rem 1rem;
    color: hsl(var(--color-primary));
    border: 2px solid hsl(var(--color-primary));
    text-decoration: none;
    text-transform: uppercase;
    font-family: var(--font2);
    letter-spacing: 1.26px;
    font-weight: 600;
    font-size: .8rem;
    border-bottom-right-radius: 1rem;
    transition: background-color .2s var(--ease-out-quad), color .2s var(--ease-out-quad);
    &:after {
        content: '';
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        width: 2rem;
        height: 2rem;
        background-image: url('../img/chevron-large--primary.svg');
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }
    &:hover {
        background-color: hsl(var(--color-primary));
        color: white;
        &:after {
            background-image: url('../img/chevron-large--white.svg');
        }
    }
}

.buttoncta__button--alt {
    flex-grow: 0;
    flex-basis: 0;
    padding: 2rem 0 2rem 1rem;
    background: transparent;
    border: none;
    color: hsl(var(--color-primary));
    border-left: 2px solid hsl(var(--color-primary));
    font-family: var(--font1);
    font-weight: 300;
    font-size: 2rem;
    line-height: 1.1;
    text-transform: unset;
    letter-spacing: 0.675px;
    justify-content: flex-start;
    @media only screen and (min-width: $lg) {
        justify-content: center;
    }
    &:after {
        display: none;
    }
    &:hover {
        background-color: transparent;
        color: hsla(var(--color-primary), 50%);
    }
}