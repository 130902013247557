.footer {
    background-color: hsl(var(--color-grey--lighter));
    overflow: hidden;
    a {
        display: inline-block;
        text-decoration: none;
        color: inherit;
        &:hover {
            color: hsl(var(--color-primary));
        }
    }
}

.footer__top {
    display: flex;
    gap: 1rem;
    padding-top: 4rem;
    flex-direction: column;
    @media only screen and (min-width: $lg) {
        flex-direction: row;
        gap: 4rem;
        padding-top: 6rem;
    }
}

.footer__top-col {
    display: flex;
    flex-direction: column;
    &:first-of-type {
        @media only screen and (min-width: $lg) {
            flex: 0 1 24rem;
        }
    }
}

.footer__tagline {
    display: block;
    font-family: var(--font1);
    font-weight: 500;
    font-size: 2rem;
    letter-spacing: -0.3px;
    line-height: 1.1;
}

.footer__logo {
    display: block;
    max-width: 200px;
    @media only screen and (min-width: $lg) {
        margin: 1rem 0 4rem;
    }
}

.footer__title {
    font-size: 1.1rem;
    font-weight: 600;
    margin: .75rem 0 .5rem 0;
    @media only screen and (min-width: $lg) {
        margin: .75rem 0 2rem 0;
    }
}

.footer__bottom {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    padding: 2rem 0 1rem;
    @media only screen and (min-width: $lg) {
        flex-direction: row;
        gap: 4rem;
        padding-bottom: 0;
    }
}

.footer__bottom-col {
    &:first-of-type {
        @media only screen and (min-width: $lg) {
            flex: 0 1 24rem;
        }
    }
    &:last-of-type {
        @media only screen and (min-width: $lg) {
            margin-left: auto;
        }
    }
}

.footer__disclaimer {
    font-size: .8rem;
    font-weight: 400;
    opacity: .5;
    a {
        font-size: inherit;
    }
}

.footer-nav {

}

.footer-nav__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $lg) {
        flex-direction: row;
        gap: 2rem;
    }
}

.footer-nav__item {

}

.footer-nav__link {
    font-weight: 500;
    font-size: .9rem;
    &:hover {
        color: hsl(var(--color-primary));
    }
}

a.footer__linkedin {
    font-size: .85rem;
    font-weight: 600;
    background-color: hsl(var(--color-primary));
    color: white;
    padding: 1rem;
    transform: translateY(0px);
    transition: transform .2s var(--ease-out-quad), opacity .2s var(--ease-out-quad);
    &:hover {
        color: white;
        transform: translateY(2px);
        opacity: .97;
    }
    @media only screen and (min-width: $lg) {
        padding: 1.5rem;
        margin-top: -1.5rem;
    }
}