.bigquote {
    font-family: var(--font1);
    text-align: center;
    font-size: clamp(1.8rem, 6vw, 3.5rem);
    margin: 4rem 0;
    padding: 0;
    @media only screen and (min-width: $lg) {
        padding: 3rem 2rem;
    }
    p {
        font-size: inherit;
        line-height: 1.2;
        font-weight: 300;
        letter-spacing: -1.075px;
        &:before {
            content: '“';
        }
        &:after {
            content: '”';
        }
    }
    
}