.sidebar {
    position: sticky;
    top: 2rem;
    background-color: hsl(var(--color-grey));
    padding: 2rem;
    border-bottom-right-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    @media only screen and (min-width: $xxl) {
        padding: 2.5rem;
    }
}

.sidebar-title {
    position: relative;
    display: flex;
    gap: .75rem;
    width: 100%;
    margin: 0 0 1.5rem 0;
    color: hsl(var(--color-primary));
    font-size: var(--font-size-h3);
    i {
        position: relative;
        top: .2rem;
    }
    &:after {
        position: absolute;
        bottom: -1.75rem;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 1.5px;
        background: hsla(var(--color-primary), 15%);
    }
}

.sidebar-body {
    a {
        color: inherit;
        font-weight: 500;
        &:hover {
            color: hsl(var(--color-primary));
        }
    }
    & + .button {
        margin-top: -.5rem;
    }
}

.sidebar-links {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.sidebar-links-item {
    font-size: .9rem;
    &:not(:last-of-type){
        margin-bottom: .2rem;
    }
}

.sidebar-links-link {
    display: inline-flex;
    gap: .75rem;
    color: hsla(var(--color-black), 70%);
    text-decoration: none;
    font-size: .9rem;
    font-weight: 600;
    i {
        position: relative;
        top: .3rem;
        transform-origin: 50% 30%;
        transition: transform .2s var(--ease-out-quad);
    }
    &:hover,
    &.is-active {
        color: hsl(var(--color-primary));
        i {
            transform: rotate(45deg);
        }
    }
}

.sidebar-sublinks {
    list-style-type: none;
    margin: 0;
    padding: .25rem 0 0 1.5rem;
}