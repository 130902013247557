.twoImages {
    @media only screen and (min-width: $md) {
        display: grid;
        align-items: center;
    }
}

.twoImages__container {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media only screen and (min-width: $lg) {
        padding-right: calc(var(--site-padding) * 3);
    }
}

.twoImages__image {
    grid-row: 1;
    grid-column: 1;

    &--first {
        width: calc(100vw - var(--site-padding) * 4);
        margin-top: -1rem;

        @media only screen and (min-width: $md) {
            margin-top: 0;
            width: 70vw;
        }

        @media only screen and (min-width: $lg) {
            width: 50vw;
        }
    }

    &--last {
        position: relative;
        width: 50vw;
        margin-top: -5rem;
        right: calc(var(--site-padding) * -1);
        

        @media only screen and (min-width: $md) {
            margin: 0;
            width: 40vw;
        }

        @media only screen and (min-width: $lg) {
            width: 35vw;
        }

        @media only screen and (min-width: $xxl) {
            width: 30vw;
        }

        @media only screen and (min-width: $xxxl) {
            width: 25vw;
        }
    }
}