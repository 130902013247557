.u-richtext {

    >*:first-child {
        margin-top: 0;
    }

    >*:last-child {
        margin-bottom: 0;
    }

    &:not(.content){
        p {
            margin-bottom: 1.5rem;
        }
    }

    

    a {
        color: hsl(var(--color-primary));
        font-weight: 500;
        &:hover {
            color: hsla(var(--color-primary), 80%);
        }
    }

    ul {
        padding-left: 1.2rem;
        li {
            &:not(:last-of-type){
                margin-bottom: .5rem;
            }
            ul {
                margin-top: .5rem;
                margin-bottom: 1rem;
            }
        }
    }

    h2 {
        color: hsl(var(--color-primary));
    }

}