.language-switcher {

}

.language-switcher__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
}

.language-switcher__item {
    display: flex;
    align-items: center;
    font-size: .8rem;
    text-transform: uppercase;
    opacity: .3;
    &:hover {
        opacity: 1;
    }
    &:not(:last-of-type){
        margin-right: .25rem;
        &:after {
            content: '|';
            margin-left: .25rem;
        }
    }
    &--current {
        opacity: 1;
        .language-switcher__link {
            &:hover {
                cursor: default;
                color: hsl(var(--color-black));
            }
        }
    }
}

.language-switcher__link {
    display: block;
    text-decoration: none;
    color: hsl(var(--color-text));
    font-size: .8rem;
    &:hover {
        color: hsl(var(--color-primary));
        opacity: 1;
    }
}