.mediumhero {
    
}

.mediumhero__content {
    position: relative;
    z-index: 2;
    line-height: 1.2;
    max-width: 64rem;
    p {
        letter-spacing: -1.375px;
        font-weight: 300;
        font-family: var(--font2);
        font-size: var(--font-size-h2);
        line-height: 1.2;
        @media only screen and (min-width: $md) {
            font-size: var(--font-size-h1);
        }
    }
} 

.mediumhero__image {
    position: relative;
    z-index: 1;
    margin-top: -.75rem;
    display: block;
    max-width: 80vw;
    margin-left: auto;
    @media only screen and (min-width: $lg) {
        max-width: 70vw;
        margin-top: -1.25rem;
    }
}