.contactgrid {
    
}

.contactgrid-row {
    display: grid;
    gap: 5px;
    @media only screen and (min-width: $lg) {
        gap: 1.5rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    &--images {
        min-height: 50rem;
    }
}

.contactgrid-col {
    position: relative;
    padding: 2rem 0;
    @media only screen and (min-width: $lg) {
        padding: 4rem 0;
    }
}

.contactgrid-col:nth-of-type(1){
    background-color: hsl(var(--color-white));
    display: grid;
    align-items: end;
    @media only screen and (min-width: $lg) {
        grid-column: 1;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -500%;
        width: 500%;
        height: 100%;
        background-color: hsl(var(--color-white));
    }
    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: -500%;
        width: 500%;
        height: 100%;
        background-color: hsl(var(--color-white));
        @media only screen and (min-width: $lg) {
            display: none;
        }
    }
    .image {
        position: relative;
        z-index: 3;
        top: -2rem;
        left: -2rem;
        width: calc(100% + 4rem);
        @media only screen and (min-width: $lg) {
            position: absolute;
            top: 4rem;
            right: 0;
            left: unset;
            width: calc(33vw + 1140px / 3);
        }
    }
}

.contactgrid-col:nth-of-type(2) {
    @media only screen and (min-width: $lg) {
        grid-column: 2 / span 2;
    }
    .image {
        display: block;
        position: relative;
        z-index: 3;
        top: -6rem;
        left: -2rem;
        width: calc(100% + 4rem);    
        height: calc(100% + 8rem);    
        @media only screen and (min-width: $lg) {
            position: absolute;
            top: 4rem;
            left: -1.5rem;
            width: calc(66vw + 1140px / 3);
        }
    }    
}

.contactgrid-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.contactgrid-item {
    &:not(:first-of-type){
        margin-top: 1rem;
        @media only screen and (min-width: $lg) {
            margin-top: 3rem;
        }
    }
    .label {
        display: block;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: .05em;
        color: hsla(var(--color-text), 50%);
        font-size: .9rem;
        @media only screen and (min-width: $xl) {
            margin-bottom: .5rem;
        }
    }
    a, p {
        color: hsl(var(--color-text));
        text-decoration: none;
        font-weight: 400;
        font-size: 1.1rem;
    }
    a:hover {
        text-decoration: underline;
        color: hsl(var(--color-primary));
    }
}