.errorpage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100dvh;
}

.errorpage-inner {
    background-color: white;
    padding: 1.5rem;
    border-bottom-right-radius: 1rem;
    @media only screen and (min-width: $md) {
        padding: 3rem;
    }
    .logo {
        display: block;
        text-align: center;
        img {
            width: 260px;
            height: auto;
            margin: 0 auto 2rem;
        }
    }
    h1 {
        font-family: var(--font2);
        margin-bottom: 2rem;
        font-size: 2.2rem;
    }
}