.history {
    display: flex;
    gap: 2rem;
    @media only screen and (min-width: $sm) {
        gap: 4rem;
    }
    @media only screen and (min-width: $md) {
        gap: 6rem;
    }
    @media only screen and (min-width: $xxl) {
        gap: 8rem;
    }
}

.history__nav {
    @media only screen and (min-width: $xl) {
        padding-left: 4rem;
    }
    @media only screen and (min-width: $xxl) {
        padding-left: 8rem;
    }
}

.history__nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.history__nav-item {
    position: relative;
    &.is-active {
        .history__nav-button {
            opacity: 1;
            cursor: default;
            color: hsl(var(--color-primary));
            &:before {
                border-color: hsl(var(--color-primary));
            }
        }
    }
}

.history__nav-button {
    display: flex;
    align-items: center;
    gap: .75rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 0 1rem 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--color-text);
    opacity: .3;
    transition: opacity .2s var(--ease-out-quad);
    &:before {
        content: '';
        display: block;
        width: .7rem;
        height: .7rem;
        border: 1.5px solid hsl(var(--color-text));
        border-radius: 100%;
    }
    
}

.history__body {
    flex: 1 1 auto;
}

.history__body-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    align-items: start;
}

.history__body-item {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s var(--ease-out-quad);
    @media only screen and (min-width: $md) {
        flex-direction: row;
    }
    .content {
        max-width: 32rem;
        .date {
            text-transform: uppercase;
            font-size: 1.1rem;
            letter-spacing: .005em;
            font-weight: 400;
            opacity: .4;
            margin: 0;
            @media only screen and (min-width: $md) {
                font-size: 1.4rem;
                // margin: 0 0 2rem 0;
            }
        }
        p, li, a {
            @extend h2;
            line-height: 1.4;
            font-size: clamp(1rem, 4vw, 1.6rem);
        }
        ul {
            margin: 0;
            padding: 0 0 0 1rem;
            li:not(:last-of-type) {
                margin-bottom: 1.5rem;
            }
        }
    }
    img {
        margin-top: -1rem;
    }
    &.is-active {
        visibility: visible;
        opacity: 1;
    }
}
