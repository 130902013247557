.newslist {
    display: grid;
    gap: 1rem;
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
    @container (min-width: 500px){
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @container (min-width: 900px){
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.5rem;
    }
}

.newslist-item {
    position: relative;
    background-color: white;
    border-bottom-right-radius: var(--border-radius);
    transition: background-color .1s var(--ease-out-quad);
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: hsl(var(--color-primary));
        opacity: 0;
        transition: opacity .1s var(--ease-out-quad);
    }
}

.newslist-link {
    display: block;
    height: 100%;
    text-decoration: none;
    color: inherit;
}

.newslist-inner {
    position: relative;
    height: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-between;
}

.newslist-bottom {
    padding-right: 3rem;
}

.newslist-title {
    letter-spacing: unset;
    font-family: $font1;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    color: hsla(var(--color-black), 85%);
    margin: 0;
    transition: color .2s var(--ease-out-quad);
}

.newslist-categories {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.newslist-categories-item {
    font-size: .75rem;
    color: hsl(var(--color-primary));
    font-weight: 600;
    &:not(:last-of-type){
        &:after {
            content: '/';
            margin: 0 .25rem;
        }
    }
}

.newslist-date {
    font-size: .75rem;
    font-weight: 600;
    opacity: .6;
}

.newslist-icon {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    font-size: 1.5rem;
    opacity: .6;
    transition: opacity .2s var(--ease-out-quad);
}

/* ------------------------------------------------------ */
/*                         states                         */
/* ------------------------------------------------------ */

.newslist-item:hover {
    //background-color: hsla(var(--color-white), 80%);
    &:before {
        opacity: 1;
    }
    .newslist-title {
        color: hsla(var(--color-black), 100%);
    }
    .newslist-icon {
        opacity: 1;
    }
}