.property {

}

.property-address {
    font-weight: 600;
    font-size: 1.1rem;
}

.property-available {
    display: block;
    margin-top: .5rem;
}

.property-body {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid hsla(var(--color-black), 5%);
}

.property-imagegrid {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid hsla(var(--color-black), 5%);
    display: grid;
    gap: 1rem;
    @media only screen and (min-width: $sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (min-width: $md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media only screen and (min-width: $xl) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.property-imagegrid-image {
    overflow: hidden;
    @media only screen and (min-width: $sm) {
        aspect-ratio: 1 / 1;
    }
    img {
        transition: transform .2s var(--ease-out-quad), opacity .2s var(--ease-out-quad);
    }
    &:hover {
        img {
            transform: scale(1.05);
            opacity: .9;
        }
    }
}