* {
	box-sizing: border-box;
}

html {
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
  	-webkit-tap-highlight-color: transparent;
	scroll-behavior: smooth;
	// height: 100%;
}

body {
	font-family: $font1;
	background-color: hsl(var(--color-grey--light));
	color: hsl(var(--color-text));
	line-height: 1.6;
	// height: 100%;
	// display: flex;
  	// flex-direction: column;
}

// header {
// 	flex-shrink: 0;
// }

main {
	position: relative;
	// flex: 1 0 auto;
	min-height: 70vh;
}

// footer {
// 	flex-shrink: 0;
// }

::selection {
	background-color: hsl(var(--color-primary));
	color: white;
}

img {
	display: block;
	width: 100%;
}

iframe {
	max-width: 100%;
}