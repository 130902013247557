.cta-newsletter {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $lg) {
        flex-direction: row;
        align-items: center;
    }
}

.cta-newsletter__image {
    position: relative;
    aspect-ratio: 4 / 3;
    @media only screen and (min-width: $lg) {
        flex: 0 0 30rem;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.cta-newsletter__body {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: hsl(var(--color-grey));
    padding: 2rem var(--site-padding);
    gap: 2rem;
    @media only screen and (min-width: $md) {
        padding: 4rem 3rem;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom-right-radius: 1rem;
    }
    .text {
        margin: 0;
        font-family: var(--font3);
        color: hsl(var(--color-black), 30%);
        font-size: clamp(1.8rem, 5vw, 3rem);
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: -0.5px;
    }
}