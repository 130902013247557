$sm: 460px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1536px;
$xxxl: 1920px;

$font1: 'inter-tight', sans-serif;
$font2: 'inter', sans-serif;
$font3: 'plusjakartasans', sans-serif;


:root {
  --color-primary: 20, 68%, 36%;
  --color-black: 190, 6%, 18%;
  --color-white: 0, 0%, 100%;
  --color-grey: 45, 8%, 91%;
  --color-grey--light: 60, 5%, 96%;
  --color-grey--lighter: 60, 5%, 98%;
  --color-grey--dark: 45, 8%, 85%;
  
	--color-text: var(--color-black);

  --font1: $font1;
  --font2: $font2;
  --font3: $font3;

  --site-padding: 1.5rem;
  --section-padding: clamp(4rem, 10vw, 6rem);
  --border-radius: 1rem;

  --header-height: 90px;

  @media only screen and (min-width: $lg) {
    --header-height: 125px;
  }
}