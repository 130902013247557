.bighero {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100svh;
    overflow: hidden;
    @media only screen and (min-width: $md) {
        height: auto;
        min-height: calc(100vh - 3rem);
    }
}

.bighero__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
        object-fit: cover;
        object-position: 50% 0;
        width: 100%;
        height: 100%;
    }
}

.bighero__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, .75) 0%, rgba(217, 217, 217, .23) 100%);
    opacity: 1;
}

.bighero__container {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 5rem;
}

.bighero__title {
    display: block;
    margin: 2rem 0;
    color: white;
    font-family: var(--font3);
    font-size: clamp(2.2rem, 8vw, 6.4rem);
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: -1.65px;
    user-select: none;
    text-shadow: 0px 0px 60px hsl(var(--color-black));
    opacity: 0;
    @media only screen and (min-width: $md) {
        margin: 4rem 0;
    }
}