:root {
	--font1: 'inter-tight', sans-serif;
	--font2: 'inter', sans-serif;
	--font3: 'plusjakartasans', sans-serif;

	--font-size-h1: 3rem;
	--font-size-h2: clamp(1.7rem, 5vw, 2.2rem);
	--font-size-h3: clamp(1.6rem, 5vw, 2.0rem);
	--font-size-h4: 1.8rem;
}


$headings: 6;
@for $i from 1 through $headings {
	h#{$i} {
		margin-bottom: 0;
		font-family: var(--font2);
		font-size: var(--font-size-h#{$i});
		line-height: 1.2;
		letter-spacing: -.04em;
		font-weight: 300;
	}
	.h#{$i} {
		font-family: $font2;
		font-size: var(--font-size-h#{$i});
		line-height: 1.2;
	}
}

p, ul, li, a, time {
	margin: 0;
	letter-spacing: .03em;
	&:last-child {
		margin-bottom: 0;
	}
}

p, ul {
	a {
		color: hsl(var(--color-primary));
		font-weight: 500;
		&:hover {
			text-decoration: none;
		}
	}
}

strong {
	font-weight: 500;
}

.t-label {
	text-transform: uppercase;
	font-weight: 600;
	color: hsl(var(--color-text));
	font-size: 0.8rem;
    letter-spacing: 1.44px;
	opacity: .3;
}

.t-sectionLabel {
	font-family: var(--font3);
	text-transform: uppercase;
	font-size: 1.1rem;
	letter-spacing: .075em;
	font-weight: 600;
	&--center {
		@media only screen and (min-width: $md) {
			text-align: center;
		}
	}
}