.content {
    display: grid;
    gap: 1.5rem;
    align-content: start;
}

.content__block {

}

.content__block--lead {
    &:not(:first-child){
        padding-top: 4rem;
    }
}

.content__block--iframe {
    &:not(:first-child){
        margin: 1.5rem 0;
    }
}