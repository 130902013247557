.container {
    margin: 0 auto;
    width: 100%;
    max-width: 90rem;
    padding-left: var(--site-padding);
    padding-right: var(--site-padding);
}

.container--sm {
    max-width: 70rem;
}

.container--md {
    max-width: 80rem;
}

.container--relative {
    position: relative;
}

.container--full {
    &--lg {
        padding-left: 0;
        padding-right: 0;
        @media only screen and (min-width: $lg) {
            padding-left: var(--site-padding);
            padding-right: var(--site-padding);
        }
    }
}