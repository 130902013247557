.download {
    background-color: white;
    border-bottom-right-radius: var(--border-radius);
    border: 1px solid hsla(var(--color-grey));
    padding: 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    max-width: 38rem;
    text-decoration: none;
    color: hsl(var(--color-text))!important;
    &:hover {
        color: hsl(var(--color-primary))!important;
    }
}

.download-title {
    font-family: var(--font1);
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3;
}

.download-icon {
    font-size: 2rem;
}
