.cta-slider {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
    @media only screen and (min-width: $lg) {
        display: block;
    }
}

.cta-slider__slider {
    @media only screen and (min-width: $lg) {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: calc(50% - 4rem);
        height: 100%;
        overflow: hidden;
    }
    @media only screen and (min-width: $xl) {
        width: calc(50% - 8rem);
    }
}

.cta-slider__slider-viewport {
    height: 100%;
}

.cta-slider__slider-container {
    display: flex;
    user-select: none;
    height: 100%;
    .slide {
        flex: 0 0 100%;
        min-width: 0;
        height: 100%;
        aspect-ratio: 1 / 1;
        @media only screen and (min-width: $lg) {
            aspect-ratio: unset;
        }
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.cta-slider__container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
}

.cta-slider__body {
    @media only screen and (min-width: $lg) {
        width: 50%;
    }
}

.cta-slider__title {
    margin: 1rem 0 2rem;
    font-size: .8rem;
    font-family: var(--font3);
    font-weight: 700;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    @media only screen and (min-width: $lg) {
        margin: 2rem 0 6rem;
    }
}

.cta-slider__quote {
    margin: 0;
    padding: 0;
    @media only screen and (min-width: $lg) {
        max-width: 35rem;
    }
    p {
        letter-spacing: -1.075px;
        font-weight: 300;
        font-size: clamp(1.8rem, 5vw, 2.8rem);
        line-height: 1.2;
    }
    strong {
        font-weight: 600;
        opacity: .9;
    }
}

.cta-slider__text {
    margin: 0;
    padding: 0;
    opacity: 0;
    p {
        font-weight: 400;
        font-size: 1rem;
    }
    strong {
        font-weight: 600;
        opacity: .9;
    }
}

.cta-slider__band {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    width: 100%;
    padding: 2rem 0 2rem 0;
    background-color: hsl(var(--color-grey));
    transition: filter .4s var(--ease-out-quad);
    @media only screen and (min-width: $lg) {
        margin-top: 4rem;
        padding: 5rem;
        border-bottom-right-radius: 1rem;
        width: 50%;
    }
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: -500%;
        width: 500%;
        height: 100%;
        background-color: hsl(var(--color-grey));
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: -500%;
        width: 500%;
        height: 100%;
        background-color: hsl(var(--color-grey));
        @media only screen and (min-width: $lg) {
            display: none;
        }
    }
    &.is-active {
        filter: brightness(.93);
    }
}

.cta-slider__link {

}